<template>
  <div class="d-flex justify-content-end">
    <div v-if="manageIncome()">
      <CButton
        v-if="!isCashed()"
        color="primary"
        size="sm"
        class="mr-2 text-uppercase"
        @click.prevent="onMarkAsCashed"
      >
        {{ INVOICE_ACTIONS_LABEL.MARK_AS_CASHED }}
      </CButton>
      <CBadge color="success" v-if="isCashed()" class="mr-2"> incassato</CBadge>
    </div>
    <dropdown-menu :items="actions" :select="onActionSelect" class="ml-auto">
      <font-awesome-icon icon="ellipsis-h"
    /></dropdown-menu>
  </div>
</template>

<script>
import get from "lodash/get";
import {
  INVOICE_ACTIONS,
  INVOICE_ACTIONS_LABEL,
} from "../../../../config/global";

export default {
  name: "InvoicesOutTableActions",

  data() {
    return {
      INVOICE_ACTIONS_LABEL,
    };
  },

  inject: ["invoicingSettings"],

  computed: {
    actions() {
      const actions = [];
      if (!this.isCashed()) {
        actions.push({
          id: INVOICE_ACTIONS.CANCEL_INVOICE,
          text: INVOICE_ACTIONS_LABEL.CANCEL_INVOICE,
        });
      }

      actions.push(
        ...[
          {
            id: INVOICE_ACTIONS.DOWNLOAD_PDF,
            text: INVOICE_ACTIONS_LABEL.DOWNLOAD_PDF,
          },
          {
            id: INVOICE_ACTIONS.DOWNLOAD_CSV,
            text: INVOICE_ACTIONS_LABEL.DOWNLOAD_CSV,
          },
        ]
      );

      if (this.manageIncome() && this.isCashed()) {
        actions.push({
          id: INVOICE_ACTIONS.CANCEL_CASHED,
          text: INVOICE_ACTIONS_LABEL.CANCEL_CASHED,
        });
      }
      return actions;
    },
  },

  methods: {
    isCashed() {
      return get(this.data, "cashed", false);
    },

    manageIncome() {
      return this.invoicingSettings.manage_income;
    },

    onMarkAsCashed() {
      this.$store.commit("invoices/openCashModal", {
        invoice: this.data,
      });
    },

    onActionSelect(args) {
      const { id } = args.item;
      const { file_pdf, file_csv } = this.data;

      if (id === INVOICE_ACTIONS.DOWNLOAD_PDF && file_pdf) {
        window.open(file_pdf, "_blank");
      }

      if (id === INVOICE_ACTIONS.DOWNLOAD_CSV && file_csv) {
        window.open(file_csv, "_blank");
      }

      if (id === INVOICE_ACTIONS.CANCEL_INVOICE) {
        this.$store.commit("invoices/openConfirmModal", {
          action: INVOICE_ACTIONS.CANCEL_INVOICE,
          invoice: this.data,
        });
      }

      if (id === INVOICE_ACTIONS.CANCEL_CASHED) {
        this.$store.commit("invoices/openConfirmModal", {
          action: INVOICE_ACTIONS.CANCEL_CASHED,
          invoice: this.data,
        });
      }
    },
  },
};
</script>
