<template>
  <div>
    <CRow class="align-items-end">
      <CCol md="3">
        <UiDatePicker
          label="Dal"
          :date.sync="filters.start"
          :clearButton="false"
          :readonly="true"
          :max-date="new Date()"
          :masks="masks"
        />
      </CCol>
      <CCol md="3">
        <UiDatePicker
          label="Al"
          :date.sync="filters.end"
          :clearButton="false"
          :readonly="true"
          :min-date="filters.start"
          :max-date="new Date()"
          :masks="masks"
        />
      </CCol>
    </CRow>

    <div class="mb-3" v-if="hasAggregatedData">
      <font-awesome-icon icon="money-check-alt" />
      <span class="ml-2 mr-2">Totale fatture emesse</span>
      <strong>{{ totalIssued() }}</strong>

      <div v-if="manageIncome()" class="d-inline">
        <span class="ml-2 mr-2">Totale fatture incassate</span>
        <strong>{{ totalCollected() }}</strong>

        <span class="ml-2 mr-2">Totale fatture da incassare</span>
        <strong>{{ totalToBeCollected() }}</strong>
      </div>
    </div>

    <CRow class="mb-2">
      <CCol>
        <CDropdown
          togglerText="AZIONI MULTIPLE"
          color="primary"
          :disabled="actionsDisabled"
        >
          <CDropdownItem
            v-if="manageIncome()"
            @click="markAsCashedSelected(true)"
            >Segna tutto come incassato</CDropdownItem
          >
          <CDropdownItem
            v-if="manageIncome()"
            @click="markAsCashedSelected(false)"
            >Segna tutto come NON incassato</CDropdownItem
          >
          <CDropdownItem @click="downloadPdfSelected()"
            >Scarica pdf</CDropdownItem
          >
        </CDropdown>
      </CCol>
    </CRow>

    <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
      {{ error.general().join(" ") }}
    </CAlert>

    <div class="table-wrapper">
      <ejs-grid
        ref="grid"
        :dataSource="dm"
        :allowPaging="true"
        :query="query"
        :selectionSettings="selectionSettings"
        :pageSettings="pageSettings"
        :dataBound="onDataBound"
        @checkBoxChange="onCheckboxChange"
      >
        <e-columns>
          <e-column type="checkbox" width="40" textAlign="left"></e-column>
          <e-column
            headerText="N"
            :template="numberTemplate"
            width="100"
          ></e-column>
          <e-column
            headerText="Data"
            :template="dateTemplate"
            width="150"
          ></e-column>
          <e-column
            :template="destinataryTemplate"
            field="id"
            headerText="Destinario"
            isPrimaryKey="{true}"
          ></e-column>
          <e-column
            headerText="Tipo"
            :template="typeTemplate"
            width="150"
          ></e-column>
          <e-column
            headerText="N Articoli"
            :template="productsCountTemplate"
            width="150"
          ></e-column>
          <e-column
            headerText="Importo"
            :template="totalTemplate"
            width="150"
          ></e-column>
          <e-column
            headerText=""
            :template="actionsTemplate"
            width="250"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import last from "lodash/last";
import { Page } from "@syncfusion/ej2-vue-grids";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import {
  addAndPredicateFilter,
  formatDate,
  formatPrice,
  str2bytes,
} from "../../../../helpers/common";
import UiDatePicker from "../../../../common/form/UiDatePicker";
import InvoicesTableNumber from "./InvoicesTableNumber";
import InvoicesTableDate from "./InvoicesTableDate";
import InvoicesTableDestinatary from "./InvoicesTableDestinatary";
import InvoicesTableType from "./InvoicesTableType";
import InvoicesTableProductsCount from "./InvoicesTableProductsCount";
import InvoicesTableTotal from "./InvoicesTableTotal";
import InvoicesOutTableIssuedActions from "./InvoicesOutTableIssuedActions";
import { GetDataManagerNew } from "../../../../ds";
import errorResponse from "../../../../helpers/error";
import EventBus from "../../../../helpers/EventBus";
import { saveAs } from "file-saver";

export default {
  name: "InvoicesOutToBeIssued",

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  components: {
    UiDatePicker,
  },

  provide: {
    grid: [Page],
  },

  inject: ["invoicingSettings"],

  data() {
    const roleId = this.$store.state.role.id;
    const dm = GetDataManagerNew("role_invoices_emitted", [roleId]);

    return {
      error: errorResponse(),
      dm: dm,
      query: this.prepareQuery(this.filters),
      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },
      pageSettings: {
        pageSize: 10,
        pageSizes: [10, 20, 50],
      },

      actionsDisabled: true,

      masks: {
        input: "DD/MM/YYYY",
      },

      // aggregates
      totAmount: null,
      totAmountCashed: null,
      totAmountNotCashed: null,

      numberTemplate: () => {
        return { template: InvoicesTableNumber };
      },

      dateTemplate: () => {
        return { template: InvoicesTableDate };
      },

      destinataryTemplate: () => {
        return { template: InvoicesTableDestinatary };
      },

      typeTemplate: () => {
        return { template: InvoicesTableType };
      },

      productsCountTemplate: () => {
        return { template: InvoicesTableProductsCount };
      },

      totalTemplate: () => {
        return { template: InvoicesTableTotal };
      },

      actionsTemplate: () => {
        return { template: InvoicesOutTableIssuedActions };
      },
    };
  },

  computed: {
    hasAggregatedData() {
      return (
        this.totalAmount !== null &&
        this.totAmountCashed !== null &&
        this.totAmountNotCashed !== null
      );
    },
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    onDataBound() {
      try {
        const dataModule = this.$refs.grid.getDataModule();
        const response = JSON.parse(
          get(
            last(dataModule.dataManager.requests),
            "httpRequest.response",
            null
          )
        );
        if (response) {
          this.totalAmount = get(response, "payload.tot_amount", null);
          this.totAmountCashed = get(
            response,
            "payload.tot_amount_cashed",
            null
          );
          this.totAmountNotCashed = get(
            response,
            "payload.tot_amount_not_cashed",
            null
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    onCheckboxChange() {
      const selected = this.$refs.grid.getSelectedRecords();
      this.actionsDisabled = selected.length === 0;
    },

    manageIncome() {
      return this.invoicingSettings.manage_income;
    },

    totalIssued() {
      return this.totalAmount !== null
        ? formatPrice(this.totalAmount, this.currencySymbol).format()
        : "";
    },

    totalCollected() {
      return this.totAmountCashed !== null
        ? formatPrice(this.totAmountCashed, this.currencySymbol).format()
        : "";
    },

    totalToBeCollected() {
      return this.totAmountNotCashed !== null
        ? formatPrice(this.totAmountNotCashed, this.currencySymbol).format()
        : "";
    },

    prepareQuery(params) {
      const search = get(params, "search");
      const invoiceType = get(params, "invoiceType");
      const start = get(params, "start");
      const end = get(params, "end");

      let predicate = null;

      if (invoiceType) {
        predicate = addAndPredicateFilter(
          predicate,
          "i_type",
          "equal",
          invoiceType
        );
      }

      if (start && end) {
        predicate = addAndPredicateFilter(
          predicate,
          "data_emitted",
          "greaterthan",
          start.toISOString()
        );

        predicate = addAndPredicateFilter(
          predicate,
          "data_emitted",
          "lessthan",
          end.toISOString()
        );
      }

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = ["i_to.name"];
      if (search) {
        query = query.search(search, searchFields);
      }
      return query;
    },

    doSearch(params) {
      this.query = this.prepareQuery(params);
    },

    markAsCashedSelected(cashed) {
      const roleId = this.$store.state.role.id;

      const selectedIds = this.$refs.grid
        .getSelectedRecords()
        .map((item) => item.id);

      if (selectedIds.length > 0) {
        this.error.reset();
        this.$store
          .dispatch("invoices/cashInvoicesBatch", {
            roleId,
            ids: selectedIds,
            cashed: cashed,
          })
          .then(() => {
            EventBus.$emit("invoices:refresh");
          })
          .catch((error) => {
            this.error.set(get(error, "response.data.errors"));
          });
      }
    },

    downloadPdfSelected() {
      const roleId = this.$store.state.role.id;

      const selectedIds = this.$refs.grid
        .getSelectedRecords()
        .map((item) => item.id);

      if (selectedIds.length > 0) {
        this.error = null;
        this.$store
          .dispatch("invoices/downloadEmittedInvoiceBatch", {
            roleId,
            ids: selectedIds,
          })
          .then((response) => {
            const blob = new Blob([str2bytes(response.data)], {
              type: "application/zip",
            });
            saveAs(
              blob,
              `${formatDate(new Date(), "yyyyMMdd")}_invoices_emitted.zip`
            );
          })
          .catch((error) => {
            console.error(error);
            this.error.set(get(error, "response.data.errors"));
          });
      }
    },
  },
};
</script>
